import React, { Component } from 'react';
import { Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import { Row, Column } from '../common/grid';

class ChangePassword extends Component {
  state = {
    errors: false,
    errorMessage: null,
    oldPassword: '',
    newPassword: ''
  };

  validateForm = () => {
    if (this.state.oldPassword.trim().length > 0 && this.state.newPassword.trim().length > 0) {
      this.setState({ errors: false });
      return true;
    } else {
      this.setState({ errors: true });
      return false;
    }
  };

  handleSubmit = () => {
    this.setState({ errorMessage: null, errors: false });
    if (this.validateForm()) {
      this.props.handleChangePassword(this.state.oldPassword, this.state.newPassword);
    } else {
      this.setState({ errors: true });
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Column>
            <Form>
              <Column>
                <Form.Input
                  icon='lock'
                  type='password'
                  label="What's your current password?"
                  onChange={event =>
                    this.setState({
                      oldPassword: event.target.value
                    })
                  }
                />
                <Form.Input
                  icon='lock'
                  type='password'
                  label='Input a new password'
                  onChange={event =>
                    this.setState({
                      newPassword: event.target.value
                    })
                  }
                />
                <Button primary onClick={this.handleSubmit}>
                  change now
                </Button>
                {this.state.errors ? <Message>All fields required</Message> : null}
                {this.state.errorMessage ? <Message>{this.state.errorMessage}</Message> : null}
              </Column>
            </Form>
          </Column>
          <Column />
        </Row>
        <Dimmer inverted active={this.props.loading}>
          <Loader content='Sending email' />
        </Dimmer>
      </div>
    );
  }
}

export default ChangePassword;
