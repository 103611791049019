import { Table, Popup, Icon } from 'semantic-ui-react';
import { Link } from 'gatsby';
import React from 'react';

const JobRow = props => {
  const { job } = props;
  return (
    <Table.Row>
      <Table.Cell style={{ textAlign: 'center' }}>{new Date(job.updatedAt).toLocaleDateString()}</Table.Cell>
      <Table.Cell>{job.name}</Table.Cell>
      <Table.Cell>{job.city}</Table.Cell>
      <Table.Cell>{job.country}</Table.Cell>
      <Table.Cell>
        <Popup
          content={job.jobTitle}
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          on='hover'
          trigger={<span>{job.jobTitle.substring(0, 20)}</span>}
        />
      </Table.Cell>
      <Table.Cell>
        <Popup
          content={job.jobDescription.substring(0, 500)}
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          on='hover'
          trigger={<span>{job.jobDescription.substring(0, 20)}</span>}
        />
      </Table.Cell>
      <Table.Cell style={{ textAlign: 'center' }}>
        <Popup
          content={'Edit your job post. If your posting is two weeks old, then this will update its post date to today as well.'}
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          on='hover'
          trigger={
            <button onClick={props.handleEdit} style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}>
              <Icon name='pencil' color='black' />
            </button>
          }
        />
      </Table.Cell>
      <Table.Cell style={{ textAlign: 'center' }}>
        <button style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}>
          <Link to={`/job?jobId=${job._id}`}>
            <Icon name='search' color='grey' />
          </Link>
        </button>
      </Table.Cell>
      <Table.Cell style={{ textAlign: 'center' }}>
        <Popup
          content={"Renewing a job updates your job post's post date to today, moving it to the top of results."}
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          on='hover'
          trigger={
            <button onClick={props.handleRenew} style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}>
              <Icon name='refresh' color='blue' />
            </button>
          }
        />
      </Table.Cell>
      <Table.Cell style={{ textAlign: 'center' }}>
        <Popup
          content={'Delete this job'}
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          on='hover'
          trigger={
            <button onClick={() => props.handleDelete(job._id)} style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}>
              <Icon name='trash' color='red' />
            </button>
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default JobRow;
