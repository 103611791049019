import { Button } from 'semantic-ui-react';
import ChangePassword from './changePassword';
import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { clearUserId } from '../../js/actions/index';
import { USER_API_URL } from '../../constants/constants';
import Axios from 'axios';
import { toast } from 'react-toastify';

class manageAccount extends Component {
  state = {
    isChangePassword: false
  };

  toggleChangePassword = () => {
    this.setState({ isChangePassword: !this.state.isChangePassword });
  };

  handleLogout = () => {
    localStorage.removeItem('userId');
    this.props.clearUserId();
    navigate('/');
  };

  handleChangePassword = (oldPassword, newPassword) => {
    const data = {
      id: this.props.userId,
      oldPassword,
      newPassword
    };
    Axios.post(`${USER_API_URL}/change-password`, data).then(response => {
      if (response.data.success) {
        this.handleLogout();
        toast.success('Password updated. Please log in again.');
      } else {
        toast.error('Error: ' + response.data.message);
      }
    });
  };

  render() {
    return (
      <div>
        <Button primary style={{ marginRight: '10' }} onClick={this.toggleChangePassword}>
          change my password
        </Button>
        <Button primary onClick={this.handleLogout}>
          logout
        </Button>
        {this.state.isChangePassword ? <ChangePassword handleChangePassword={this.handleChangePassword} /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.userId };
};

const mapDispatchToProps = dispatch => {
  return {
    clearUserId: userId => dispatch(clearUserId(userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(manageAccount);
