import React, { Component } from 'react';
import { Form, Button, Message, Dimmer, Loader } from 'semantic-ui-react';
import { Row, Column } from '../common/grid';

class EditJob extends Component {
  state = {
    name: '',
    email: '',
    country: '',
    city: '',
    jobDescription: '',
    errors: false,
    loading: false,
    jobTitle: '',
    _id: ''
  };

  componentDidMount = async () => {
    const { city, country, email, jobTitle, jobDescription, name, _id } = this.props.job;
    this.setState({
      _id: _id,
      city: city,
      country: country,
      email: email,
      jobTitle: jobTitle,
      jobDescription: jobDescription,
      name: name
    });
  };

  render() {
    return this.props.isEditMode ? (
      <Form>
        <Row>
          <Column>
            <Form.Input
              placeholder='School or Company Name'
              type='text'
              value={this.state.name}
              label='School or Company Name'
              onChange={() =>
                this.setState({
                  name: event.target.value
                })
              }
            />
          </Column>
          <Column>
            <Form.Input
              placeholder='Unable to edit email address'
              type='text'
              label='Your Email Address'
              value={this.state.email}
              disabled
              onChange={() =>
                this.setState({
                  email: event.target.value
                })
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Form.Input
              placeholder='Job Title'
              type='text'
              label='Job Title'
              value={this.state.jobTitle}
              onChange={() =>
                this.setState({
                  jobTitle: event.target.value
                })
              }
            />
          </Column>
          <Column>
            <Form.Input
              placeholder='City'
              type='text'
              label='City'
              value={this.state.city}
              onChange={() =>
                this.setState({
                  city: event.target.value
                })
              }
            />
          </Column>
          <Column>
            <Form.Input
              placeholder='Country'
              type='text'
              label='Country'
              value={this.state.country}
              onChange={() => {
                this.setState({
                  country: event.target.value
                });
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Form.TextArea
              placeholder='Describe the job. Talk about stuff like salary, class size, and whatnot'
              label='Job Description'
              type='text'
              value={this.state.jobDescription}
              onChange={() =>
                this.setState({
                  jobDescription: event.target.value
                })
              }
            />
          </Column>
        </Row>
        <div style={{ marginTop: '10px', textAlign: 'center' }}>
          {this.state.errors ? <Message negative>All Fields Are Required</Message> : null}
          <Button onClick={this.props.handleCancelEdit}>Cancel</Button>
          <Button primary onClick={() => this.props.handleSaveEdit(this.state)}>
            Save
          </Button>
        </div>
      </Form>
    ) : (
      <Dimmer inverted active={this.state.loading}>
        <Loader content='Loading account' />
      </Dimmer>
    );
  }
}

export default EditJob;
