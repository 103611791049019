import React, { Component } from 'react';
import { Menu, Dimmer, Loader, Confirm } from 'semantic-ui-react';
import Axios from 'axios';
import MyJobs from '../components/account/myJobs';
import ManageAccount from '../components/account/manageAccount';
import EditJob from '../components/job/editJob';
// @ts-ignore
import { API_URL } from '../constants/constants';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class Account extends Component {
  state = {
    userId: undefined,
    activeItem: 'myJobs',
    loading: true,
    userJobs: [],
    isConfirmOpen: false,
    idToDelete: undefined,
    isEditMode: false,
    jobToEdit: undefined
  };

  componentDidMount = () => {
    this.getMyJobs();
    const userId = this.props.userId;
    if (!userId) {
      navigate('/login');
    }
  };

  getMyJobs = async () => {
    const res = await fetch(`${API_URL}/jobs?jobPosterId=` + localStorage.getItem('userId'));
    const data = await res.json();
    const newUserJobs = data.jobs;
    this.setState({ userJobs: newUserJobs, loading: false });
  };

  handleCancel = () => {
    this.setState({ isConfirmOpen: false, loading: false });
  };

  handleConfirm = () => {
    this.setState({ isConfirmOpen: false, loading: false });
    Axios.delete(`${API_URL}/job?jobId=` + this.state.idToDelete)
      .then(response => {
        if (response.data.success) {
          const newJobs = this.state.userJobs.filter(el => {
            return el._id !== this.state.idToDelete ? el : null;
          });
          this.setState({
            userJobs: newJobs,
            loading: false,
            idToDelete: undefined
          });
          toast.success('Job deleted');
        } else {
          toast.error('Error deleting job');
        }
      })
      .catch(error => toast.error('Error: ' + error));
  };

  handleRenew = jobId => {
    Axios.put(`${API_URL}/renew?jobId=` + jobId).then(response => {
      if (response.data.success) {
        toast.success('Job renewed');
      } else {
        toast.error('Error renewing job');
      }
    });
  };

  handleEdit = async jobId => {
    this.setState({ isEditMode: true, loading: true, jobToEdit: undefined });
    const res = await fetch(`${API_URL}/job?jobId=` + jobId);
    const data = await res.json();
    this.setState({ loading: false, jobToEdit: data.job });
  };

  handleCancelEdit = () => {
    this.setState({ loading: false, isEditMode: false });
  };

  handleSaveEdit = job => {
    Axios.put(`${API_URL}/job`, job).then(response => {
      if (response.data.success) {
        toast.success('Edit saved successfully');
        this.setState({ isEditMode: false });
        this.getMyJobs();
      } else {
        toast.error('Error updating job');
        this.setState({ isEditMode: false });
        this.getMyJobs();
      }
    });
  };

  handleDelete = jobId => {
    this.setState({ loading: true, idToDelete: jobId, isConfirmOpen: true });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <>
        <Helmet
          title={`eslbot | account`}
          meta={[
            { name: 'description', content: `Manage your account. Renew, delete, or edit your posted jobs. Change your password. Log out` },
            {
              name: 'keywords',
              content: `eslbot esl bot account`
            },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover'
            }
          ]}
        />
        <Menu>
          <Menu.Item name='myJobs' active={activeItem === 'myJobs'} onClick={() => this.setState({ activeItem: 'myJobs' })}>
            My Jobs
          </Menu.Item>

          <Menu.Item
            name='manageAccount'
            active={activeItem === 'manageAccount'}
            onClick={() => this.setState({ activeItem: 'manageAccount' })}
          >
            Manage Account
          </Menu.Item>
        </Menu>
        {this.state.activeItem === 'myJobs' ? (
          <div>
            <MyJobs
              handleDelete={this.handleDelete}
              handleRenew={this.handleRenew}
              handleEdit={this.handleEdit}
              jobs={this.state.userJobs}
            />
            <Confirm
              open={this.state.isConfirmOpen}
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirm}
              header='This will permanently delete this job posting'
              size='tiny'
              style={{ marginTop: '-150px' }}
            />
            {this.state.isEditMode && this.state.jobToEdit ? (
              <EditJob
                isEditMode={this.state.isEditMode}
                handleCancelEdit={this.handleCancelEdit}
                handleSaveEdit={this.handleSaveEdit}
                job={this.state.jobToEdit}
              />
            ) : null}
          </div>
        ) : null}
        {this.state.activeItem === 'manageAccount' ? (
          <ManageAccount handleLogout={this.props.handleLogout} handleChangePassword={this.props.handleChangePassword} />
        ) : null}
        <Dimmer inverted active={this.state.loading}>
          <Loader content='Loading account' />
        </Dimmer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.userId };
};

export default connect(mapStateToProps, null)(Account);
