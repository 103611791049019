import { Table } from 'semantic-ui-react';
import JobRow from './jobRow';
import { Button, Icon, Message } from 'semantic-ui-react';
import { Link } from 'gatsby';
import React from 'react';

const placeholder = (
  <div style={{ textAlign: 'center' }}>
    <Message>It looks like you haven't posted any jobs yet. Once you do, you can manage your jobs here.</Message>
    <Link to='/post-job'>
      <Button primary>
        <Icon name='plus' />
        Post Job
      </Button>
    </Link>
  </div>
);

const MyJobs = props => {
  const jobs = props.jobs;
  return jobs && !props.editMode ? (
    <div>
      {props.jobs.length > 0 ? (
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ textAlign: 'center' }}>Posted Date</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>City</Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>Job Title</Table.HeaderCell>
              <Table.HeaderCell>Job Description</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>Edit</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>View</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>Renew</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>Delete</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {jobs.map((job, index) => {
              return (
                <JobRow
                  job={job}
                  key={index}
                  handleDelete={props.handleDelete}
                  handleRenew={() => props.handleRenew(job._id)}
                  handleEdit={() => props.handleEdit(job._id)}
                />
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='10' />
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : null}

      {jobs.length == 0 ? placeholder : null}
    </div>
  ) : null;
};

export default MyJobs;
